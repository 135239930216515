<script>
    export let job

    import CompanyLogo from '@/components/listing/CompanyLogo.svelte';
    import Location from '@/icons/Location.svelte';
    import Time from '@/icons/Time.svelte';
    import License from '@/icons/License.svelte';
    import Calendar from '@/icons/Calendar.svelte';
    import PremiumBadge from '@/components/listing/PremiumBadge.svelte';

    import moment from 'moment';
    import { link } from 'svelte-routing';
    import { getAvailableFileSize, newlinesToBreak } from '@/lib/utils';

    const formatJobAvailableAt = (date) => {
        return moment(date).format("DD.MM.YYYY");
    }

    let src;

    if(job.title_images.data[0]) {
        src = getAvailableFileSize(job.title_images.data[0], "lg")
    } else if(job.employer.title_images.data[0]) {
        src = getAvailableFileSize(job.employer.title_images.data[0], "lg")
    } else {
        src = "/images/employee-1.jpg"
    }
</script>

<a use:link href={job.slug} class="swiper-slide" ref="slide" style="height: unset">
    <div class="bg-white max-h-[800px] rounded-2xl shadow-md p-2 overflow-y-hidden">
        {#if job.is_premium}
            <PremiumBadge mainClass="!top-4 !right-4 !p-1"/>
        {/if}
        <div class="relative aspect-[4/2.5] w-full">
            <img class="w-full h-full rounded-2xl object-cover object-center" src="{src}" alt=""/>
            {#if job?.employer?.logos?.data?.length > 0}
                <CompanyLogo image={job.employer.logos.data[0].thumb} mainClass="absolute -bottom-[55px] left-2 h-[100px] w-[100px] absolute -bottom-[55px] left-2 !rounded-2xl border-4 border-white shadow-xl"/>
            {:else}
                <img class="absolute -bottom-[55px] left-2 h-[100px] w-[100px] !rounded-2xl border-2 border-white shadow-xl" src="/images/logo-placeholder.png" alt="Logo Platzhalter"/>
            {/if}
        </div>

        <div class="ml-[120px] mt-2 text-moss font-medium">
            <p class="text-sm">{job.employer.company}</p>
            <div class="inline-flex items-center gap-1">
                <Location mainClass="text-poisongreen w-4 h-4"/>
                <p class="text-sm line-clamp-1 overflow-ellipsis">{job.zipcode} {job.location}</p>
            </div>
        </div>

        <div class="mt-8 flex flex-row gap-1.5">
            <div class="w-1/3 shadow-xl shadow-sandlight/50 rounded-3xl bg-sandlight/80">
                <div class="py-3 px-0.5 inline-flex flex-col gap-1 items-center w-full">
                    <Time mainClass="text-limegreen h-12 w-12"/>
                    <p class="leading-none text-xs mt-0.5">
                        Pensum:
                    </p>
                    <p class="text-earth font-bold leading-none text-xs">
                        {job.working_time}
                    </p>
                </div>
            </div>
            <div class="w-1/3 shadow-xl shadow-sandlight/50 rounded-3xl bg-sandlight/80">
                <div class="py-3 px-0.5 inline-flex flex-col gap-1 items-center w-full">
                    <License mainClass="text-limegreen h-12 w-12"/>
                    <p class="leading-none text-xs mt-0.5">
                        Vertrag:
                    </p>
                    <p class="text-earth font-bold leading-none text-xs">
                        {job.employment_type}
                    </p>
                </div>
            </div>
            <div class="w-1/3 shadow-xl shadow-sandlight/50 rounded-3xl bg-sandlight/80">
                <div class="py-3 px-0.5 inline-flex flex-col gap-1 items-center w-full">
                    <Calendar mainClass="text-limegreen h-12 w-12"/>
                    <p class="leading-none text-xs mt-0.5">
                        Verfügbar ab:
                    </p>
                    <p class="text-earth font-bold leading-none text-xs">
                        {formatJobAvailableAt(job.job_available_at)}
                    </p>
                </div>
            </div>
        </div>

        <div class="p-2 mt-6">
            <h2 class="text-2xl font-semibold text-moss">
                {job.position.name} <span class="font-light text-xl">(m/w/d)</span>
            </h2>
            <div class="mt-2">
                {@html newlinesToBreak(job.description)}
            </div>
        </div>
    </div>
    <div class="bg-gradient-to-t from-sandlight via-sandlight h-32 absolute -bottom-2 -left-4 -right-4 z-50"></div>
</a>
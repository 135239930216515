import _ from "lodash";
window._ = _;

/**
 * Instantiate Sentry for Logging
 */
// import * as Sentry from "@sentry/svelte";

// Sentry.init({
//   dsn: "https://530e9e81ed6e63118c871ae36fa056ce@o4508330125164544.ingest.de.sentry.io/4508330126540880",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/green-careers\.de\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// Create the Event Bus for emitting Events globally
import { EventBus } from "./event-bus";
window.eventbus = new EventBus("event-bus");

/**
 * Get the user if logged in
 * and check if the user is impersonating
 */
import { api as user } from "@/services/user";
import { service as employerService, employer } from "@/services/employer.js";
import { get } from "svelte/store";

if (window.meta.user) {
    await user
        .getUser()
        .then((response) => {
            console.debug("User logged in");

            window.Echo.private(`App.Models.Employer.${get(employer).data.id}`).listen(".EmployerUpdated", (data) => {
                window.eventbus.emit("EmployerUpdated", data.model)
            });

            return response;
        })
        .catch((error) => {
            console.debug("User logged out");
            return error;
        });
}

/**
 * Bootstraps the Svelte Application
 * on the DOM 'app' Element
 * and exports app
 */
import App from "./App.svelte";

window.app = new App({
  target: document.getElementById('app')
})

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */
import './echo';

window.eventbus.on("EmployerUpdated", async ( event ) => {
    await employerService.show({
        id: event.detail.id
    })
});

<script>
    export let mode;
    export let formID;

    import Pin from '@/icons/Pin.svelte';
    import Upload from '@/components/form/Upload.svelte';
    import BigInput from '@/components/form/BigInput.svelte';
    import Textarea from '@/components/form/Textarea.svelte';
    import Geocoder from "@/components/filter/Geocoder.svelte"
    import LoadingDots from "@/components/LoadingDots.svelte";
    import CompanyLogo from '@/components/listing/CompanyLogo.svelte';
    import InformationBox from '@/components/InformationBox.svelte';
    import ReorderUploads from '@/components/form/ReorderUploads.svelte';
    import Stroke from '@/icons/Stroke.svelte';
    import Slider from '@/components/Slider.svelte';
    
    import { jobAdvertisement, employer as tempEmployer } from "@/stores/temporary";
    import { slide } from 'svelte/transition';
    import { tick } from 'svelte';
    import { getAvailableFileSize, newlinesToBreak } from '@/lib/utils';

    let mapLoading = false;
    
    const setLocation = async (location) => {
        jobAdvertisement.update((store) => {
            store.data = {
                ...store.data,
                ...{
                    street: location.detail.street || location.detail.street_number 
                        ? `${location.detail.street ?? ""} ${location.detail.street_number ?? ""}`
                        : "",
                    location: location.detail.location,
                    zipcode: location.detail.zipcode,
                    country: location.detail.country,
                    latitude: location.detail.latitude,
                    longitude: location.detail.longitude
                }
            }

            return store;
        })

        mapLoading = true;
        
        await tick();

        mapLoading = false;
    };

    const getMapLink = () => {
        return `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/${$jobAdvertisement.data.longitude},${$jobAdvertisement.data.latitude},18,0/720x405?access_token=${import.meta.env.VITE_MAPBOX_TOKEN}`;
    }

    let files = $tempEmployer.data.files.data;
</script>

<div transition:slide>
    <section class="pt-6">
        <h1 class="text-2xl font-semibold text-moss">
            Über Uns
        </h1>
        <h2 class="text-xl font-semibold text-limegreen">
            {$tempEmployer.data.company}
        </h2>
    </section>

    <section class="mt-2 relative">
        {#if mode === "view"}
            {#if $tempEmployer.data.files.data.length > 0}  
                <Slider id={$tempEmployer.data.id}>
                    {#each $tempEmployer.data.files.data as file}
                        <div class="swiper-slide h-full flex flex-col justify-center" style="height: unset;">
                            {#if file.url.endsWith(".mp4")}
                                <video controls 
                                    class="bg-moss border-2 shadow-lg border-white rounded-xl w-full aspect-video">
                                    <source src="{file.url}" type="video/mp4"/>
                                    <track kind="captions"/>
                                </video>
                            {:else}
                                <img 
                                    src="{getAvailableFileSize(file, 'lg')}" 
                                    alt="{file.properties.originalFilename}" 
                                    class="bg-moss border-2 object-contain shadow-lg border-white rounded-xl mx-auto w-auto"
                                />
                            {/if}
                        </div>
                    {/each}
                </Slider>
            {/if}
        {:else if mode === "edit"}
            <Upload 
                bind:files={$tempEmployer.data.files}
                type="horizontal"
                title="Bilder oder Videos hochladen"
                message=" Laden Sie hier unternehmensbezogene Bilder und Videos hoch. Der Bewerber muss erkennen, wie sein Alltag bei Ihnen aussieht."
                maxFileSize="512MB"
                allowMultiple={true}
                acceptedFileTypes={["image/jpg","image/jpeg","image/png","image/webp","video/mp4"]}
                formID={formID}
                name="files"
            />

            <ReorderUploads 
                files={files} 
                resource_class_name="Employer" 
                resource_id="{$tempEmployer.data.id}" 
                collection_name="files"
            />
        {/if}
    </section>

    {#if mode === "view"}
        {#if $tempEmployer.data.number_of_employees || $tempEmployer.data.founding_year}
            <section class="py-6 relative">
                <div class="flex flex-row gap-4 max-w-lg mx-auto">
                    {#if $tempEmployer.data.number_of_employees}
                        <InformationBox mainClass="rounded-xl shadow-xl p-4 w-1/2 bg-white/50 text-limegreen font-bold mx-auto" formatter={(value) => value} number={$tempEmployer.data.number_of_employees} duration={1500}>
                            <p class="!font-medium !text-moss">
                                Mitarbeiter
                            </p>
                        </InformationBox>
                    {/if}
                    {#if $tempEmployer.data.founding_year}
                        <InformationBox mainClass="rounded-xl shadow-xl p-4 w-1/2 bg-white/50 text-limegreen font-bold mx-auto" formatter={(value) => value} number={$tempEmployer.data.founding_year} duration={1500}>
                            <p class="!font-medium !text-moss">
                                Gegründet
                            </p>
                        </InformationBox>
                    {/if}
                </div>
            </section>
        {/if}
    {:else if mode === "edit"}
        <section class="py-6 relative">
            <h2 class="text-xl font-semibold text-limegreen">
                Ihre Unternehmensdaten
            </h2>
            <div class="flex flex-row gap-4 max-w-lg mx-auto">
                <div class="p-1 -mx-0.5 w-1/2">
                    <BigInput
                        bind:value={$tempEmployer.data.number_of_employees}
                        name="number_of_employees"
                        label="Anzahl Mitarbeiter"
                        placeholder="0000"
                        type="number"
                        {formID}
                    />
                </div>
                <div class="p-1 -mx-0.5 w-1/2">
                    <BigInput
                        bind:value={$tempEmployer.data.founding_year}
                        name="founding_year"
                        label="Gründungsjahr"
                        placeholder="0000"
                        type="number"
                        {formID}
                    />
                </div>
            </div>
        </section>
    {/if}

    <section class="py-6">
        {#if mode === "view"}
            {@html newlinesToBreak($jobAdvertisement.data.employer.description)}
        {:else if mode === "edit"}
            <Textarea
                bind:value={$tempEmployer.data.description}
                name="description"
                placeholder='Beschreiben Sie Ihr Unternehmen ausführlich.'
                type="text"
                {formID}
                containerClass="w-full my-3"
                inputClass="!bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white text-xl"
            />
        {/if}

        {#if $jobAdvertisement.data.is_premium && $jobAdvertisement.data.employer.careers_page}
            <div class="mt-3">
                <a href={$jobAdvertisement.data.employer.careers_page} class="button !py-2 limegreen !no-underline outlined !inline-flex !w-auto">
                    unsere Karriereseite
                </a>
            </div>
        {/if}
    </section>

    <section class="py-6">
        <h2 class="text-2xl font-semibold text-moss inline-flex flex-col gap-1 items-start">
            Hier findest du uns
            <Stroke mainClass="inline-block text-limegreen h-1 w-auto"/>
        </h2>

        <div class="relative mt-4">
            {#if mapLoading === false}
                {#if $jobAdvertisement.data.longitude && $jobAdvertisement.data.latitude}
                    <div class="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
                        <div class="relative w-24 h-24">
                            <div class="h-18 w-18 absolute top-1.5 left-2 right-2 aspect-square bg-moss rounded-full m-1">
                                {#if $tempEmployer?.data?.logos?.data?.length > 0}
                                    <CompanyLogo mainClass="h-full w-full !rounded-full border-2 border-white shadow-xl" image={getAvailableFileSize($tempEmployer.data.logos.data[0])}/>
                                {:else if $tempEmployer?.data?.logos[0]}
                                    <img class="h-full w-full rounded-full border-2 border-white shadow-xl object-cover" src="{`/uploads/${$tempEmployer?.data?.logos[0].path}`}" alt="Logo Platzhalter"/>
                                {:else}
                                    <img class="h-full w-full rounded-full border-2 border-white shadow-xl object-cover" src="/images/logo-placeholder.png" alt="Logo Platzhalter"/>
                                {/if}
                            </div>
                            <Pin mainClass="text-white"/>
                        </div>

                        <div class="text-center mt-8">
                            <a class="button sm limegreen shadow-xl !border-2 border-poisongreen" target="_blank" href={`http://www.google.com/maps/dir/?api=1&destination=${$jobAdvertisement.data.latitude},${$jobAdvertisement.data.longitude}`}>
                                Route Planen
                            </a>
                        </div>
                    </div>
                    <img 
                        class="rounded-xl border-2 border-white aspect-video"
                        alt="Map" 
                        src="{getMapLink()}"
                    />
                {/if}
            {:else}
                <div class="w-full aspect-video bg-moss/10 rounded-md shadow-md flex items-center justify-center">
                    <LoadingDots/>
                </div>
            {/if}

            {#if mode === "edit"}
                <Geocoder
                    bind:value={$jobAdvertisement.data.street}
                    on:location={(location) => setLocation(location)}
                    required={false}
                    name="street"
                    placeholder="Adresse"
                    type="text"
                    {formID}
                    containerClass="w-full mt-3"
                    inputClass="!bg-poisongreen/20 !border-limegreen !text-moss placeholder:!text-limegreen focus:!bg-white"
                />
            {/if}
        </div>
    </section>
</div>
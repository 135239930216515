<script>
    import { onMount } from "svelte"

    import Swiper, { Pagination, Navigation, Scrollbar } from 'swiper';
    Swiper.use([Pagination, Navigation, Scrollbar]);

    let slider = undefined;

    function setSlideOpacity(swiper) {
        if(window.matchMedia('(max-width: 768px)').matches) {
            swiper.slides.forEach((slide, index) => {
                const slideOffset = Math.abs(slide.progress);
                const opacity = 1 - (slideOffset + 0) + 0.3;
                const scale = 1 - (slideOffset + 0) + 0.93;

                slide.style.opacity = opacity;
                slide.style.scale = Math.min(1, Math.max(0, scale))

                if(index === swiper.activeIndex) {
                    slide.style.transform = `rotate(0deg)`;
                    slide.style.opacity = 1;
                }
            });
        }
    }

    onMount(() => {
        new Swiper(slider, {
            watchSlidesProgress: true,
            pagination: {
                el: '.testimonial-swiper-pagination',
                clickable: true,
            },
            grabCursor: true,
            spaceBetween: 15,
            breakpoints: {
                0: {
                    loop: true,
                    slidesPerView: 1,
                    centeredSlides: true,
                },
                768: {
                    loop: false,
                    slidesPerView: 3,
                    centeredSlides: false,
                }
            },
            on: {
                slideChangeTransitionStart: function () {
                    setSlideOpacity(this);
                },
                setTranslate: function () {
                    setSlideOpacity(this);
                },
            },
        })
    })
</script>

<main>
    <div class="mx-1 mt-12 relative">
        <div class="testimonial-slider overflow-visible relative mx-auto">
            <div bind:this={slider}>
                <div class="swiper-wrapper">
                    <div class="swiper-slide" style="height: unset !important;">
                        <div class="bg-white p-1 rounded-md shadow-lg h-full">
                            <img src="/images/testimonial-3.jpg" alt="testimonial" class="w-full h-auto object-cover aspect-[16/9] rounded-md object-top">

                            <div class="p-4 text-center">
                                <p class="text-xl font-bold mt-2 text-limegreen">
                                    Steffen
                                </p>
                                <p class="text-limegreen">
                                    Vorarbeiter Landschaftsbau
                                </p>
                                <p class="mt-2 text-sm">
                                    "Auf den üblichen Jobportalen habe ich zwar einige Angebote gesehen, aber die meisten haben einfach nicht zu mir gepasst. Hier lief es anders: Nachdem ich drei Unternehmen angeschrieben habe hat es nur ein paar Tage gedauert und ich wurde zu zwei Vorstellungsgesprächen eingeladen."
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide" style="height: unset !important;">
                        <div class="bg-white p-1 rounded-md shadow-lg h-full">
                            <img src="/images/testimonial-2.jpg" alt="testimonial" class="w-full h-auto object-cover aspect-[16/9] rounded-md object-top">

                            <div class="p-4 text-center">
                                <p class="text-xl font-bold mt-2 text-limegreen">
                                    Susanne
                                </p>
                                <p class="text-limegreen">
                                    Zierpflanzen-Gärtnermeisterin
                                </p>
                                <p class="mt-2 text-sm">
                                    "Eigentlich war ich gar nicht aktiv auf Jobsuche. Aber weil mir immer wieder Anzeigen von GreenCareers auf Instagram & Google aufgefallen sind, habe ich mir die Plattform einfach mal angesehen. Jetzt bin ich überrascht und kann nach dem ersten Probearbeiten schon sagen, dass es sich gelohnt hat, die Plattform zu nutzen."
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide" style="height: unset !important;">
                        <div class="bg-white p-1 rounded-md shadow-lg h-full">
                            <img src="/images/testimonial-1.jpg" alt="testimonial" class="w-full h-auto object-cover aspect-[16/9] rounded-md object-top">

                            <div class="p-4 text-center">
                                <p class="text-xl font-bold mt-2 text-limegreen">
                                    Ralf
                                </p>
                                <p class="text-limegreen">
                                    Bauleiter
                                </p>
                                <p class="mt-2 text-sm">
                                    „Ich bin überrascht, wie gut die Angebote zu meinen Vorstellungen gepasst haben - nach 2 Wochen habe ich meinen  Arbeitsvertrag unterschrieben. <br/> Absolut empfehlenswert!"
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="testimonial-swiper-pagination flex items-center !w-auto px-2 py-3 absolute !-bottom-12 !left-1/2 !right-unset p-2 !-translate-x-1/2 z-10"></div>
            </div>
        </div>
    </div>
</main>
import Api from "../api.js"
import { writable } from "svelte/store";

export const invoices = writable();
export const invoice = writable();

class InvoiceService extends Api {
    constructor() {
        super('/api/invoices', invoices, invoice, 'invoice');
    }
}

export const service = new InvoiceService('/api/invoices', invoices, invoice);
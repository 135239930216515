<script>
    import { singleSearchOpen, closeSearch, preparedSearch, missingKeys, clear, translatedKeys, searchComplete, removeItemByKey } from "@/stores/globalsearch"
    import Information from "@/icons/Information.svelte";
    import { fade } from "svelte/transition"
    import { clickOutside } from "@/lib/utils";
    import { service as jobService } from "@/services/job"
    import { navigate } from "svelte-routing";

    import Sector from "./Sector.svelte";
    import WorkingTime from "./WorkingTime.svelte";
    import JobAvailableAt from "./JobAvailableAt.svelte";
    import Location from "./Location.svelte";
    import Close from "@/icons/Close.svelte"
    import Branche from "@/icons/Branche.svelte"
    import Calendar from "@/icons/Calendar.svelte"
    import Time from "@/icons/Time.svelte"
    import LocationIcon from "@/icons/Location.svelte"

    let step = 0;
    let steps = [
        "sectors",
        "working_time",
        "job_available_at",
        "location"
    ]

    let loading = false;

    const submit = async () => {
        loading = true;
        
        await jobService.search({
            ...$preparedSearch,
            record: 1
        }).then(() => {
            navigate("/jobs");
            localStorage.setItem("singleSearchOpened", "true");
            closeSearch();
        })

        loading = false;
    }

    const next = () => {
        step++
    }

    const prev = () => {
        step--
    }
</script>

{#if $singleSearchOpen}
    <main transition:fade class="fixed top-0 bottom-0 left-0 right-0 backdrop-blur-md bg-black/20 z-50 p-4 lg:p-8 overflow-y-scroll flex flex-col justify-center">
        <div use:clickOutside on:clickOutside={() => (closeSearch())} class="max-w-3xl w-full mx-auto">
            <div class="bg-sandlight rounded-3xl relative">
                <button on:click={() => closeSearch()} class="absolute top-3 right-3 z-50 flex items-center justify-center rounded-md text-limegreen gap-1.5 text-sm font-bold leading-[0]">
                    <span>Schliessen</span>
                    <Close mainClass="text-limegreen w-6 h-6"/>
                </button>
                
                <div class="py-6 m-6 text-moss">
                    {#if step === 0}
                        <Sector>
                            <div class="mb-4 shadow-moss/50 bg-gradient-to-t from-moss/80 to-limegreen/90 w-24 h-24 mx-auto rounded-2xl flex items-center justify-center shadow-lg">
                                <Branche mainClass="w-12 h-12 text-sandlight"/>
                            </div>

                            <p class="text-2xl font-bold text-center text-earth">
                                In welcher Branche möchtest du arbeiten?
                            </p>

                            <div class="flex flex-row gap-2 my-2 text-limegreen justify-center">
                                <Information mainClass="w-5"/>
                                <p>
                                    Es sind bis zu 5 Antworten möglich.
                                </p>
                            </div>
                        </Sector>
                    {:else if step === 1}
                        <WorkingTime activeClass="bg-poisongreen !text-moss" passiveClass="bg-white !text-moss">
                            <div class="mb-4 shadow-moss/50 bg-gradient-to-t from-moss/80 to-limegreen/90 w-24 h-24 mx-auto rounded-2xl flex items-center justify-center shadow-lg">
                                <Time mainClass="w-12 h-12 text-sandlight"/>
                            </div>

                            <p class="text-2xl font-bold text-center text-earth">
                                Welchen Job suchst du?
                            </p>

                            <div class="flex flex-row gap-2 my-2 text-limegreen justify-center">
                                <Information mainClass="w-5"/>
                                <p>
                                    Es ist eine Auswahl möglich
                                </p>
                            </div>
                        </WorkingTime>
                    {:else if step === 2}
                        <JobAvailableAt activeClass="bg-poisongreen !text-moss" passiveClass="bg-white !text-moss">
                            <div class="mb-4 shadow-moss/50 bg-gradient-to-t from-moss/80 to-limegreen/90 w-24 h-24 mx-auto rounded-2xl flex items-center justify-center shadow-lg">
                                <Calendar mainClass="w-12 h-12 text-sandlight"/>
                            </div>

                            <p class="text-2xl font-bold text-center text-earth">
                                Wann möchtest du starten?
                            </p>

                            <div class="flex flex-row gap-2 my-2 text-limegreen justify-center">
                                <Information mainClass="w-5"/>
                                <p>
                                    Es ist eine Auswahl möglich
                                </p>
                            </div>
                        </JobAvailableAt>
                    {:else if step === 3}
                        <Location hightlightClass="text-limegreen">
                            <div class="mb-6 shadow-moss/50 bg-gradient-to-t from-moss/80 to-limegreen/90 w-24 h-24 mx-auto rounded-2xl flex items-center justify-center shadow-lg">
                                <LocationIcon mainClass="w-12 h-12 text-sandlight"/>
                            </div>
                            <p class="text-2xl font-bold text-center text-earth">
                                In welcher Region möchtest du arbeiten?
                            </p>
                        </Location>
                    {/if}

                    {#if step < 3}
                        <section class="text-center mt-4 flex flex-row gap-4">
                            {#if step < 3 && step > 0}
                                <button on:click={prev} class="button sandlight border border-moss">
                                    Zurück
                                </button>
                            {/if}
                            <button disabled={$missingKeys.includes(steps[step]) ? true : false} on:click={next} class="button moss">
                                Weiter
                            </button>
                        </section>
                    {:else}
                        <section class="text-center mt-4 flex flex-row gap-4">
                            <button on:click={prev} class="button sandlight border border-moss">
                                Zurück
                            </button>
                            <button on:click={submit} disabled={$missingKeys.includes(steps[step])} class="button moss block {$missingKeys.includes(steps[step]) ? "disabled" : ""}">
                                Jetzt passende Jobs finden
                            </button>
                        </section>
                    {/if}
                </div>
            </div>

            <div class="flex flex-row gap-2 justify-between text-white/80 text-sm items-center mt-2 mx-12">
                <div class="relative bg-gray/30 h-2 mb-4 mt-3 rounded-lg w-full border border-earth">
                    <div class="absolute top-0 left-0 h-1.5 bg-poisongreen transition-all ease-in-out duration-300 rounded-lg" style="width: {(step + 1) * 25}%"></div>
                </div>
                <div class="w-14 text-right">
                    {step + 1} | 4
                </div>
            </div>
        </div>
    </main>
{/if}
<script>
    import Input from "@/components/form/Input.svelte";
    import TelInput from "@/components/form/TelInput.svelte";
    import Textarea from "@/components/form/Textarea.svelte";
    import Upload from "@/components/form/Upload.svelte";
    import BigInput from "@/components/form/BigInput.svelte";
    import ReorderUploads from "@/components/form/ReorderUploads.svelte";
    import Select from "@/components/form/Select.svelte";
    import Geocoder from "@/components/filter/Geocoder.svelte";

    import Information from "@/icons/Information.svelte";
    import SocialMediaToggle from "@/components/form/SocialMediaToggle.svelte";

    import { service as employerService, employer } from "@/services/employer"
    import { omit } from "lodash";
    import { success, failure } from "@/lib/toast";
    import { tick } from "svelte";
    import { objectToArray} from "@/lib/utils";
    import { merge } from "lodash";


    let formID = "employer";
    let loading = false;
    
    let activeSocialMedia = undefined;

    let socialMediaPlaceholders = {
        facebook: "https://www.facebook.com/...",
        xing: "https://www.xing.com/...",
        instagram: "https://www.instagram.com/...",
        linkedin: "https://www.linkedin.com/...",
        tiktok: "https://www.tiktok.com/...",
    }


    let data = undefined;
    let dataWithoutUploads = undefined;
    let existingUploads = undefined;

    const prepareData = (originalData) => {
        // remove file uploads from data because they are handled separately
        dataWithoutUploads = omit(originalData, ["logos", "title_images", "files", "contact_images"]);
        dataWithoutUploads = {...dataWithoutUploads, ...{
            logos: [],
            title_images: [],
            files: [],
            contact_images: []
        }};

        existingUploads = {
            logos: $employer.data.logos.data,
            title_images: $employer.data.title_images.data,
            files: $employer.data.files.data,
            contact_images: $employer.data.contact_images.data
        }

        if(originalData?.country) {
            dataWithoutUploads.country = objectToArray(window.meta.countries)
                .find((country) => country.value === originalData.country)
        }

        data = structuredClone(dataWithoutUploads);
    }

    prepareData($employer.data);

    const setLocation = (location) => {
        data = merge(data, location.detail);
        data = merge(data, {
            street: `${location.detail.street} ${location.detail.street_number ?? ""}`,
            location: location.detail.location,
            zipcode: location.detail.zipcode,
            country: location.detail.country
        });
    }

    const submit = async () => {
        let dataToSubmit = structuredClone(data);

        if(dataToSubmit?.country?.value) {
            dataToSubmit = {...dataToSubmit, ...{
                country: dataToSubmit.country.value
            }}
        }

        await employerService.update(dataToSubmit, {formID: formID})
            .then(async (response) => {
                loading = true;
                await tick();
                loading = false;

                prepareData(response.data.data);
                success("Ihre Änderungen wurden erfolgreich gespeichert.");
            })
            .catch((error) => {
                failure("Ihre Änderungen konnten nicht gespeichert werden.");
                error.response.data.message && failure(error.response.data.message);
            })
    }

    const updateEmployerTaxNumber = () => {
        if (data.country === "de" || data.country.value === "de") {
            data.tax_number = "";
        }
    }

    $: data.country && updateEmployerTaxNumber();
</script>

{#if data && loading === false}
    <main>
        <Input
            on:enter={() => submit(data)}
            bind:value={data.company}
            required={true}
            name="company"
            placeholder="Firma"
            type="text"
            {formID}
            containerClass="w-full my-3"
            inputClass="!bg-sandlight focus:!bg-white"
        />

        {#if data.street === "" && data.location === "" && data.zipcode === ""}
            <Geocoder
                placeholder="Adresse"
                bind:value={data.street}
                on:location={(location) => setLocation(location)}
                required={true}
                name="location"
                containerClass="w-full my-3"
                inputClass="!bg-sandlight focus:!bg-white"
            />
        {:else}
            <Input
                bind:value={data.street}
                required={true}
                name="street"
                placeholder="Adresse"
                type="text"
                {formID}
                containerClass="w-full my-3"
                inputClass="!bg-sandlight focus:!bg-white"
            />

            <div class="flex flex-row gap-2">
                <Input
                    bind:value={data.zipcode}
                    required={true}
                    name="zipcode"
                    placeholder="PLZ"
                    type="text"
                    {formID}
                    containerClass="w-1/2"
                    inputClass="!bg-sandlight focus:!bg-white"
                />
                <Input
                    bind:value={data.location}
                    required={true}
                    name="location"
                    placeholder="Stadt"
                    type="text"
                    {formID}
                    containerClass="w-1/2"
                    inputClass="!bg-sandlight focus:!bg-white"
                />
            </div>

            {#if window.meta.countries}
                <Select
                    bind:value={data.country}
                    required={true}
                    name="country"
                    placeholder="Land"
                    items={objectToArray(window.meta.countries)}
                    {formID}
                    label="label"
                    itemId="value"
                    containerClass="w-full my-3 light"
                    on:change={(e) => {data.country = e.detail.detail}}
                    on:input={(e) => {data.country = e.detail.detail}}
                />
            {/if}
        {/if}
        
        {#if (data?.country === "de" || data?.country?.value === "de" || data?.country === "") === false}
            <Input
                on:enter={() => submit(data)}
                bind:value={data.tax_number}
                name="tax_number"
                placeholder="Umsatzsteuer ID"
                type="text"
                {formID}
                containerClass="w-full my-3"
                inputClass="!bg-sandlight focus:!bg-white"
            />
        {/if}

        <TelInput
            bind:value={data.phone}
            inputClass="!bg-sandlight focus:!bg-white"
            formID={formID}
            name="phone"
            selectableCountries={[
                {id: "AT", iso2: "AT", dialCode: "43", name: "Österreich", label: "Österreich"},
                {id: "CH", iso2: "CH", dialCode: "41", name: "Schweiz", label: "Schweiz"},
                {id: "DE", iso2: "DE", dialCode: "49", name: "Deutschland", label: "Deutschland"},
                {id: "FR", iso2: "FR", dialCode: "33", name: "Frankreich", label: "Frankreich"},
                {id: "IT", iso2: "IT", dialCode: "39", name: "Italien", label: "Italien"},
                {id: "NL", iso2: "NL", dialCode: "31", name: "Niederlande", label: "Niederlande"},
            ]}
        />

        <Input
            on:enter={() => submit(data)}
            bind:value={data.website}
            name="website"
            placeholder="URL zu Ihrer Website"
            type="text"
            {formID}
            containerClass="w-full my-3"
            inputClass="!bg-sandlight focus:!bg-white"
        />

        <Input
            on:enter={() => submit(data)}
            bind:value={data.careers_page}
            name="careers_page"
            placeholder="URL zu Ihrer Karriereseite"
            type="text"
            {formID}
            containerClass="w-full my-3"
            inputClass="!bg-sandlight focus:!bg-white"
            tooltip="Wird nur bei Premium <br/> Inseraten angezeigt"
        />

        <Textarea 
            bind:value={data.description}
            name="description"
            {formID}
            rows={8}
            placeholder='Beschreiben Sie Ihr Unternehmen ausführlich. Dieser Text wird auf Ihrer "Über Uns"-Seite potenziellen Bewerbern angezeigt.'
            inputClass="!bg-sandlight focus:!bg-white"/>

        <div class="my-8">
            <p class="text-2xl text-moss font-bold">
                Logo:
            </p>

            <Upload 
                bind:files={data.logos}
                type="vertical"
                title="Laden Sie hier das Logo Ihres Unternehmens hoch."
                message="Das ideale Format beträgt 200x200 Pixel oder ein Seitenverhältnis von 1/1."
                acceptedFileTypes={["image/jpg","image/jpeg","image/png","image/webp"]}
                formID={formID}
                name="logos"
                maxFileSize="2MB"
            />

            <ReorderUploads 
                files={existingUploads.logos} 
                resource_class_name="Employer" 
                resource_id="{data.id}" 
                collection_name="logos"
            />
        </div>

        <div class="my-8">
            <p class="text-2xl text-moss font-bold">
                Unternehmensdaten:
            </p>

            <div class="flex flex-row gap-1">
                <div class="p-1 -mx-0.5 w-1/2">
                    <BigInput
                        bind:value={data.number_of_employees}
                        name="number_of_employees"
                        label="Anzahl Mitarbeiter"
                        placeholder="0000"
                        type="number"
                        {formID}
                    />
                </div>
                <div class="p-1 -mx-0.5 w-1/2">
                    <BigInput
                        bind:value={data.founding_year}
                        name="founding_year"
                        label="Gründungsjahr"
                        placeholder="0000"
                        type="number"
                        {formID}
                    />
                </div>
            </div>
        </div>

        <div class="my-8">
            <p class="text-2xl text-moss font-bold">
                Titelbild:
            </p>

            <Upload 
                bind:files={data.title_images}
                type="horizontal"
                title="Laden Sie hier das Titelbild für Ihre Stellenanzeigen hoch"
                message="Das ideale Format beträgt 1200x750 Pixel oder ein Seitenverhältnis von 4/2.5"
                acceptedFileTypes={["image/jpg","image/jpeg","image/png","image/webp"]}
                formID={formID}
                name="title_images"
                maxFileSize="50MB"
            />

            <ReorderUploads 
                files={existingUploads.title_images} 
                resource_class_name="Employer" 
                resource_id="{data.id}" 
                collection_name="title_images"
            />
        </div>

        <div class="my-8">
            <p class="text-2xl text-moss font-bold">
                Unternehmensbilder:
            </p>

            <Upload 
                bind:files={data.files}
                type="horizontal"
                title="Bilder oder Videos hochladen"
                message="Laden Sie hier weitere Bilder hoch, die Ihr Unternehmen perfekt widerspiegeln (z.B. Teambild, Außenansicht)"
                maxFileSize="512MB"
                allowMultiple={true}
                acceptedFileTypes={["image/jpg","image/jpeg","image/png","image/webp","video/mp4"]}
                formID={formID}
                name="files"
            />

            <ReorderUploads 
                files={existingUploads.files} 
                resource_class_name="Employer" 
                resource_id="{data.id}" 
                collection_name="files"
            />

            <div class="flex flex-row gap-2 my-2 text-limegreen">
                <Information mainClass="w-5"/>
                <p>
                    Maximal 10 Bilder (10mb)
                </p>
            </div>
        </div>

        <div class="my-8">
            <p class="text-2xl text-moss font-bold">
                Verknüpfen Sie Ihre Social-Media Accounts:
            </p>

            <div class="mt-4">
                <div class="flex flex-row gap-2">
                    <SocialMediaToggle 
                        on:select={(event) => activeSocialMedia = event.detail}
                        {formID} 
                        {activeSocialMedia} 
                        value={data.social_facebook} 
                        icon="facebook"
                    />
                    <SocialMediaToggle 
                        on:select={(event) => activeSocialMedia = event.detail}
                        {formID} 
                        {activeSocialMedia} 
                        value={data.social_xing} 
                        icon="xing"
                    />
                    <SocialMediaToggle 
                        on:select={(event) => activeSocialMedia = event.detail}
                        {formID} 
                        {activeSocialMedia} 
                        value={data.social_instagram} 
                        icon="instagram"
                    />
                    <SocialMediaToggle 
                        on:select={(event) => activeSocialMedia = event.detail}
                        {formID} 
                        {activeSocialMedia} 
                        value={data.social_linkedin} 
                        icon="linkedin"
                    />
                    <SocialMediaToggle 
                        on:select={(event) => activeSocialMedia = event.detail}
                        {formID} 
                        {activeSocialMedia} 
                        value={data.social_tiktok} 
                        icon="tiktok"
                    />
                </div>

                {#if activeSocialMedia}
                    <div class="mt-4">
                        <Input
                            on:enter={() => submit(data)}
                            bind:value={data[`social_${activeSocialMedia}`]}
                            name={`social_${activeSocialMedia}`}
                            placeholder={socialMediaPlaceholders[activeSocialMedia]}
                            type="text"
                            {formID}
                            containerClass="w-full my-3"
                            inputClass="!bg-sandlight focus:!bg-white"
                        />
                    </div>
                {/if}
            </div>
        </div>

        <div class="mt-4">
            <button class="button moss" on:click={() => submit()}>Eingaben speichern</button>
        </div>
    </main>
{/if}
<script>
    import { jobs, service as jobService } from '@/services/job';
    import {onDestroy, onMount, tick} from "svelte"

    import { openSearch, preparedSearch } from "@/stores/globalsearch"
    import { merge } from "lodash";

    import Swiper, { Pagination, Navigation, Scrollbar } from 'swiper';
    Swiper.use([Pagination, Navigation, Scrollbar]);

    import ListingSlider from '@/components/listing/ListingSlider.svelte';
    import LoadingDots from '@/components/LoadingDots.svelte';

    let loading = false;
    let type = "rotate";
    let slider = undefined;
    let swiper = undefined;

    function setSlideOpacity(swiper) {
        swiper.slides.forEach((slide, index) => {
            const slideOffset = Math.abs(slide.progress);
            const opacity = 1 - (slideOffset + 0) + 0.3;

            if(type === "rotate") {
                slide.style.opacity = opacity;
                slide.style.top = `${slideOffset * 10}px`

                if(index <= swiper.activeIndex) {
                    slide.style.transform = `rotate(-${slideOffset * 4}deg)`;
                }
                else {
                    slide.style.transform = `rotate(${slideOffset * 4}deg)`;
                }

                if(index === swiper.activeIndex) {
                    slide.style.transform = `rotate(0deg)`;
                }
            }
            else if (type === "offset") {
                slide.style.opacity = opacity;
                slide.style.top = `${slideOffset * 20}px`

                if(index === swiper.activeIndex) {
                    slide.style.transform = `rotate(0deg)`;
                }
            }
        });
    }

    const appendVacancies = async () => {
        await tick();
        swiper.update();
    }

    const setVacancies = async () => {
        await tick();
        swiper.update();
        swiper.slideTo(0);
    }

    onMount(() => {
        swiper = new Swiper(slider, {
            slidesPerView: 1,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            grabCursor: true,
            spaceBetween: 0,
            freeMode: true,
            centeredSlides: false,
            on: {
                slideChangeTransitionStart: function () {
                    setSlideOpacity(this);
                },
                setTranslate: function () {
                    setSlideOpacity(this);
                },
                reachEnd: async function () {
                    loading = true;
                    
                    if($jobs?.meta?.current_page >= $jobs?.meta?.last_page) {
                        loading = false;
                        return;
                    }

                    await jobService.search({
                        ...$preparedSearch,
                        record: 1
                    }, {
                        params: {
                            per_page: 15,
                            page: $jobs?.meta?.current_page ? $jobs?.meta?.current_page + 1 : 1,
                            append: true
                        }
                    });

                    // update the slider to recognize the new slides
                    this.update();

                    loading = false;
                }
            },
        })

        window.eventbus.on("append:vacancies", appendVacancies);
        window.eventbus.on("set:vacancies", setVacancies);
    })

    onDestroy(() => {
        window.eventbus.off("append:vacancies", appendVacancies);
        window.eventbus.off("set:vacancies", setVacancies);
    })
</script>

<main>
    <div class="mx-0 mt-12 relative">
        <div class="job-slider overflow-visible relative max-w-lg mx-auto">
            <div bind:this={slider}>
                <div class="swiper-wrapper">
                    {#if $jobs?.data?.length > 0}
                        {#each $jobs.data as job}
                            <ListingSlider {job}/>
                        {/each}
                        <div class="swiper-slide" ref="slide" style="height: unset">
                            {#if loading}
                                <div class="min-h-[800px] p-2 overflow-y-hidden flex flex-col justify-center items-center">
                                    <div>
                                        <div class="mb-2">
                                            <strong>
                                                Lade weitere Jobs...
                                            </strong>
                                        </div>
                                        <LoadingDots/>
                                    </div>
                                </div>
                            {:else}
                                <div class="min-h-[800px] p-2 overflow-y-hidden flex flex-col justify-center items-center">
                                    <div>
                                        <div class="mb-2">
                                            <strong>
                                                Keine weiteren Ergebnisse
                                            </strong>
                                        </div>
                                        <button class="button moss" on:click={(e) => openSearch()}>
                                            Filter anpassen
                                        </button>
                                    </div>
                                </div>
                            {/if}
                        </div>
                    {/if}
                </div>
                <!-- <div class="w-[168px] absolute bottom-8 left-1/2 p-4 -translate-x-1/2 z-50 bg-moss rounded-xl shadow-xl shadow-limegreen/40 overflow-x-hidden">
                    <div class="swiper-pagination px-2 !relative !translate-x-0 !top-0 !left-0 !right-0"></div>
                </div> -->
                <div class="swiper-navigation">
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                </div>
            </div>
        </div>
    </div>
</main>
<script>
    import { onDestroy, onMount } from 'svelte';
    import { jobs, premiumjobs, service as jobService } from '@/services/job';
    import { get, writable } from "svelte/store";
    import { preparedSearch, openSearch, missingKeys, clear, translatedKeys, searchComplete, removeItemByKey } from "@/stores/globalsearch"
    import { createEventDispatcher } from 'svelte';
    import Search from '@/components/Search.svelte';
    import List from '@/icons/List.svelte';
    import Grid from '@/icons/Grid.svelte';
    import JobList from './JobList.svelte';
    import JobSlider from './JobSlider.svelte';
    import Filter from '@/icons/Filter.svelte';

    const dispatch = createEventDispatcher();

    let listType = "list";

    onMount(async () => {
        dispatch('showbackdrop', false);

        // if there are missing keys, open the search
        // so that the user can fill them out
        // and trigger search after that
        if($missingKeys.length > 0) {
            openSearch()
        }
        else {
            jobService.search({
                ...$preparedSearch,
                per_page: 5
            });

            jobService.search({
                ...$preparedSearch,
                per_page: 15,
                is_premium: 1
            }, {}, premiumjobs);
        }
    })

    onDestroy(() => {
        dispatch('showbackdrop', true);
    })

    const setListType = (type) => {
        listType = type;
    }
</script>

<main class="bg-moss">
    <div>
        <div class="container mx-auto pt-20 pb-8 max-w-4xl">
            <Search/>
        </div>
    </div>
    <div class="bg-sandlight rounded-t-3xl py-4 overflow-x-hidden overflow-y-hidden">
        <div class="container mx-auto">
            <div class="flex flex-row gap-2 justify-between items-center">
                <a href="/arbeitgeber?scrollto=unsere-pakete" class="button sm white">
                    Jetzt inserieren
                </a>

                <div class="inline-flex items-center gap-2">
                    <span class="text-limegreen">Ansicht</span>
                    <div class="inline-flex items-center gap-1">
                        <button on:click={() => setListType("list")} class="w-8 h-8 transition-all ease-in-out rounded-md {listType === "list" ? 'text-limegreen' : 'text-earth/50'}">
                            <List/>
                        </button>
                        <button on:click={() => setListType("slider")} class="w-8 h-8 transition-all ease-in-out rounded-md {listType === "slider" ? 'text-limegreen' : 'text-earth/50'}">
                            <Grid/>
                        </button>
                    </div>
                </div>
            </div>

            <div>
                {#if $missingKeys.length > 0}
                    <div class="mt-16 text-center">
                        <p class="text-xl text-moss font-bold">
                            Suche nach passenden Stellenanzeigen
                        </p>
                        <p class="text-limegreen">
                            Bitte konfiguriere Deine Suche um Ergebnisse zu sehen
                        </p>
                        <button on:click={() => openSearch()} class="button sm limegreen flex items-center gap-2 !w-auto mx-auto !text-white !rounded-full mt-3">
                            Suche
                            <Filter mainClass="text-white"/>
                        </button>
                    </div>
                {:else}
                    {#if listType === "list"}
                        <JobList/>
                    {:else if listType === "slider"}
                        <JobSlider/>
                    {/if}
                {/if}
            </div>
        </div>
    </div>
</main>
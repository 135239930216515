<script>
    import { multiSearchOpen, closeSearch, preparedSearch, missingKeys, clear, translatedKeys, searchComplete, removeItemByKey } from "@/stores/globalsearch"
    import Information from "@/icons/Information.svelte";
    import Filter from "@/icons/Filter.svelte"
    import { fade } from "svelte/transition"
    import { clickOutside } from "@/lib/utils";

    import { service as jobService, jobs, job } from "@/services/job"

    import CurrentSelection from "./CurrentSelection.svelte";
    import Sector from "./Sector.svelte";
    import WorkingTime from "./WorkingTime.svelte";
    import JobAvailableAt from "./JobAvailableAt.svelte";
    import Location from "./Location.svelte";
    import Close from "@/icons/Close.svelte"
    import { navigate } from "svelte-routing";

    let formID = "search";

    const submit = () => {
        jobService.search({
            ...$preparedSearch,
            record: 1
        }, {formID: formID}).then(() => {
            closeSearch();
            navigate("/jobs")
        })
    }
</script>

{#if $multiSearchOpen}
    <main transition:fade class="fixed top-0 bottom-0 left-0 right-0 backdrop-blur-md z-50 p-4 lg:p-8 overflow-y-scroll">
        <div use:clickOutside on:clickOutside={closeSearch} class="bg-moss rounded-3xl max-w-3xl mx-auto relative my-16">
            <button on:click={() => closeSearch()} class="absolute right-3 top-3 z-50 mr-0.5 flex items-center justify-center rounded-md text-white font-bold leading-[0]">
                <Close mainClass="text-poisongreen w-6 h-6"/>
            </button>
            <div class="p-4 lg:p-10 mb-8 text-snow">
                <h2>Filter</h2>
                
                <hr class="my-6 border-snow/20"/>
                
                <CurrentSelection/>

                <hr class="my-6 border-snow/20"/>

                <Sector formID={formID}>
                    <p>
                        In welcher Branche möchtest du arbeiten?
                    </p>

                    <div class="flex flex-row gap-2 my-2 text-poisongreen">
                        <Information mainClass="w-5"/>
                        <p>
                            Es sind bis zu 5 Antworten möglich.
                        </p>
                    </div>
                </Sector>

                <hr class="my-6 border-snow/20"/>

                <WorkingTime formID={formID}>
                    <p>
                        Welchen Job suchst du?
                    </p>

                    <div class="flex flex-row gap-2 my-2 text-poisongreen">
                        <Information mainClass="w-5"/>
                        <p>
                            Es ist eine Auswahl möglich
                        </p>
                    </div>
                </WorkingTime>

                <hr class="my-6 border-snow/20"/>

                <JobAvailableAt formID={formID}>
                    <p>
                        Wann möchtest du starten?
                    </p>

                    <div class="flex flex-row gap-2 my-2 text-poisongreen">
                        <Information mainClass="w-5"/>
                        <p>
                            Es ist eine Auswahl möglich
                        </p>
                    </div>
                </JobAvailableAt>
                
                <hr class="my-6 border-snow/20"/>

                <Location formID={formID}>
                    <p class="mb-2">
                        In welcher Region möchtest du arbeiten?
                    </p>
                </Location>

                <hr class="my-6 border-snow/20"/>

                <section class="text-center">
                    <button on:click={submit} class="button gradient">
                        Jetzt passende Jobs finden
                    </button>
                    <button on:click={() => clear()} class="underline text-poisongreen mt-2 inline-flex flex-row gap-1 text-sm">
                        <Filter mainClass="w-6 text-poisongreen"/>
                        Zurücksetzen
                    </button>
                </section>
            </div>
        </div>
    </main>
{/if}